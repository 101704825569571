import axios from "axios";
import { CUSTOM_FONTS } from "../components/design-editor/constants/custom-fonts";

// export const SOCKET_ENDPOINT = "http://localhost:5000";
export const SOCKET_ENDPOINT = "https://dev-api.plutis.io";
export const API_ENDPOINT = `${SOCKET_ENDPOINT}/api`;
// export const API_ENDPOINT = "http://192.168.5.204:5000/api";

// export const STRIPE_PUBLISHABLE_KEY = 'pk_test_51M5HYBFt8SF8cRVQIfH0p6XObDRme4gOTNFLr1NGWijK4SZziQ0ctZOKN4qaFgEvbnerkNj4mqGASpjBvqSCDKb500jCgcaT4X';
export const STRIPE_PUBLISHABLE_KEY = 'pk_live_51M5HYBFt8SF8cRVQeDTnyzeywWtDgbUJkdXA3nK6DhPsLBJdMCOOXYXbzyH9loJESTQgOKJcBNImPdkHQbRLdXQb00G24t0uEA';

const allowedPathsByAccountType = {
  "agency": ["/integrations", "/dashboard"],
  "root": ["/integrations", "/dashboard", "/lead-generation", "/creative-suite", "/customer-retention", "/review-management"],
  "subaccount": ["/dashboard", '/customer-retention', '/customer-retention/inbox']
}

function toTwilioCredentials(authentication) {

  return { username: authentication.accountSid, password: authentication.authToken }
}

export async function fetchBrandColors(url) {
  return await fetch(`${API_ENDPOINT}/v1/creative_editor/brand_colors/generate?url=${url}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred.");
      return null;
    }
    const data = await response.json();
    console.log(data)
    return data;
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });
}

export async function listBrandColors() {
  return await fetch(`${API_ENDPOINT}/v1/creative_editor/brand_colors/list`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred.");
      return null;
    }
    const data = await response.json();
    return data;
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });
}

export async function getTwilioAuthentication() {
  return await fetch(`${API_ENDPOINT}/v1/twilio/auth`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred.");
      return null;
    }
    const data = await response.json();
    return data;
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });
}

export async function getOptimizedMessage(message) {
  return await fetch(`${API_ENDPOINT}/v1/text_optimizer`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
    body: JSON.stringify({
      message: message
    }),
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred.");
      return null;
    }
    const data = await response.json();
    return data;
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });
}

export const authenticateUser = async (email, password) => {
  try {
    const response = await fetch(`${API_ENDPOINT}/v1/end_user/authenticate`, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        password
      }),
    });

    if (!response.ok) {
      throw new Error('Authentication failed');
    }

    const result = await response.json();

    if (result && result.user_id) {
      localStorage.setItem("userId", result.user_id);
      localStorage.setItem("accessToken", result.token);
      // localStorage.setItem("userRoles", result.roles);
      // localStorage.setItem("accountType", result.account_type);

      return {
        needs_onboarding: result.needs_onboarding || false
      };
    }

    throw new Error('Invalid response from server');
  } catch (error) {
    console.error("Authentication error:", error);
    throw error;
  }
};

export async function submitOnboardingData(onboardingData) {
  console.log("Submitting onboarding data:", onboardingData);
  return await fetch(`${API_ENDPOINT}/v1/onboarding`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
    body: JSON.stringify(onboardingData),
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred during onboarding submission.");
      return null;
    }
    const data = await response.json();
    console.log("Onboarding submission response:", data);
    return data;
  }).catch(error => {
    console.error("An error occurred during onboarding submission:", error);
    return null;
  });
}

export const checkUserAuthentication = async () => {
  console.log("Checking user authentication...");
  await fetch(`${API_ENDPOINT}/v1/end_user/session/check`, {
    method: 'GET',
    headers: {
      "Content-Type": "application/json",
      'x-access-token': localStorage.getItem('accessToken')
    },
  })
    .then((res) => {
      if (res.status == 200) return res.json();
      return null;
    })
    .then(async (result) => {
      if (result && result.user_id) {
        localStorage.setItem("userRoles", result.roles);
        localStorage.setItem("accountType", result.account_type);
        localStorage.setItem("needsOnboarding", (result.needs_onboarding || true).toString());
        
        const needsOnboarding = result.needs_onboarding;
        const currPathName = window.location.pathname.split('?')[0].split('#')[0];
        
        if (needsOnboarding) {
          if (currPathName !== "/onboarding") {
            window.location.href = '/onboarding';
          }
        } else if (!result.account_type.includes('root')) {
          if (result.account_type.includes('agency')) {
            if (!allowedPathsByAccountType.agency.includes(currPathName)) {
              window.location.href = "/";
            }
          } else if (result.account_type.includes('subaccount')) {
            if (!allowedPathsByAccountType.subaccount.includes(currPathName)) {
              window.location.href = "/";
            }
          } else {
            window.location.href = '/';
          }
        }
      } else {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("needsOnboarding");
        window.location.href = '/sign-in';
      }
    });
}

export const deauthenticateUser = async() => {
  await fetch(`${API_ENDPOINT}/v1/end_user/session/invalidate`, {
    method: 'GET',
    headers: {
      "Content-Type": "application/json",
      'x-access-token': localStorage.getItem('accessToken')
    },
  })
    .then((res) => {
      if (res.status == 200) return res.json();
      return null;
    })
    .then(async (result) => {
      localStorage.removeItem("accessToken");
      window.location.href = '';
    });
  
}

export async function fetchDashboardData() {
  return await fetch(`${API_ENDPOINT}/v1/dashboard/data`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred.");
      return null;
    }
    const data = await response.json();
    return data;
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });
}

export async function fetchCallLeads() {
  return await fetch(`${API_ENDPOINT}/v1/call_analytics/leads/list`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred.");
      return null;
    }
    const data = await response.json();
    return data;
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });
}


export async function addFormDestination(emailString) {
  return await fetch(`${API_ENDPOINT}/v1/form_submissions/add/destination`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
    body: JSON.stringify({
      emails: emailString
    })
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred.");
      return null;
    }
    const data = await response.json();
    return data;
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });
}

export async function fetchCombinedLeads() {
  return await fetch(`${API_ENDPOINT}/v1/leads/combined`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred.");
      return null;
    }
    const data = await response.json();
    return data.combined_leads;
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });
}

export async function fetchFormLeads() {
  return await fetch(`${API_ENDPOINT}/v1/form_submissions/leads/list`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred.");
      return null;
    }
    const data = await response.json();
    return data;
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });
}

export async function getLeadGenerationPreferences() {
  return await fetch(`${API_ENDPOINT}/v1/lead_generation/preferences`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred.");
      return null;
    }
    const data = await response.json();
    return data;
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });
}

export async function retrieveSiteInformationForLeadGenerationPreferences(website_url) {
  return await fetch(`${API_ENDPOINT}/v1/lead_generation/preferences/site_scan?website_url=${website_url}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred.");
      return null;
    }
    const data = await response.json();
    return data;
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });
}

export async function updateLeadGenerationPreferences(preferences) {
  return await fetch(`${API_ENDPOINT}/v1/lead_generation/preferences`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
    body: JSON.stringify(preferences),
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred.");
      return null;
    }
    const data = await response.json();
    return data;
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });
}

export async function listUpcomingContent(ucLimit = 10, ucOffset = 0) {
  return await fetch(`${API_ENDPOINT}/v1/upcoming_content/list?uc_limit=${ucLimit}&uc_offset=${ucOffset}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred.");
      return null;
    }
    const data = await response.json();
    return data;
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });
}

export async function getUpcomingContentDetails(contentId) {
  return await fetch(`${API_ENDPOINT}/v1/upcoming_content/${contentId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred.");
      return null;
    }
    const data = await response.json();
    return data;
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });
}

export async function getReviewManagementPreferences() {
  return await fetch(`${API_ENDPOINT}/v1/review_management/preferences`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred.");
      return null;
    }
    const data = await response.json();
    return data;
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });
}


export async function updateReviewManagementPreferences(preferences) {
  return await fetch(`${API_ENDPOINT}/v1/review_management/preferences`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
    body: JSON.stringify(preferences),
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred.");
      return null;
    }
    const data = await response.json();
    return data;
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });
}

export async function listReviewRequests(limit = 10, offset = 0) {
  return await fetch(`${API_ENDPOINT}/v1/review_requests/list?limit=${limit}&offset=${offset}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred.");
      return null;
    }
    const data = await response.json();
    return data;
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });
}

export async function createReviewRequest(requestData) {
  // console.log(requestData);
  return await fetch(`${API_ENDPOINT}/v1/${requestData.platform}/reviews/request`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
    body: JSON.stringify({
      contact_data: requestData.contactData,
      request_type: requestData.requestType,
      custom_message: requestData.customMessage
    }),
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred.");
      return null;
    }
    const data = await response.json();
    return data;
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });
}

export async function listReviews(limit = 10, offset = 0) {
  return await fetch(`${API_ENDPOINT}/v1/reviews/list?limit=${limit}&offset=${offset}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred.");
      return null;
    }
    const data = await response.json();
    return data;
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });
}

export async function createReviewReply(platform, reviewId, reviewReply) {
  return await fetch(`${API_ENDPOINT}/v1/${platform}/reviews/respond`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
    body: JSON.stringify({
      review_id: reviewId,
      review_reply: reviewReply
    }),
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred.");
      return null;
    }
    const data = await response.json();
    return data;
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });
}

export async function listMessengerConversations() {
  return await fetch(`${API_ENDPOINT}/v1/meta/messenger/conversations`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred.");
      return null;
    }
    const data = await response.json();
    return data;
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });
}

export async function listMessengerConversationMessages(conversationId) {
  return await fetch(`${API_ENDPOINT}/v1/meta/messenger/conversations/${conversationId}/messages`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred.");
      return null;
    }
    const data = await response.json();
    return data;
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });

}

export async function listRetentionChats(phoneNumber, authentication, limit = 10, offset = 0) {
  const sortByDate = (a, b) => Date.parse(a.date_created) > Date.parse(b.date_created) ? -1 : 1
  let result = await fetch(`${API_ENDPOINT}/v1/retention/chats/list?limit=${limit}&offset=${offset}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred.");
      return null;
    }
    const data = await response.json();
    return data;
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });
  return result.chats;
}

export async function listChatMessages(phoneNumber, chatId, authentication, limit = 10, offset = 0) {
  const sortByDate = (a, b) => Date.parse(a.date_created) > Date.parse(b.date_created) ? -1 : 1
  let result = await fetch(`${API_ENDPOINT}/v1/retention/chats/${chatId}/messages?limit=${limit}&offset=${offset}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred.");
      return null;
    }
    const data = await response.json();
    return data;
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });
  result = (result && result.messages) ? result.messages : [];
  for (var i = 0; i < result.length; i++) {
    result[i].date_created = result[i].date_created?.$date;
    result[i].date_sent = result[i].date_sent?.$date;
    result[i].date_updated = result[i].date_updated?.$date;
  }
  result = result.filter(r => Date.parse(r.date_created) > 1712954525056);
  let messages = result.sort(sortByDate);
  return { messages }
}

export async function markAsRead(messageIds = []) {
  if (messageIds.length > 0) {
    return await fetch(`${API_ENDPOINT}/v1/retention/messages/mark_read`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('accessToken')
      },
      body: JSON.stringify({
        message_ids: messageIds
      })
    }).then(async (response) => {
      if (!response.ok) {
        console.log("An error occurred.");
        return null;
      }
      const data = await response.json();
      return data;
    }).catch(error => {
      console.log("An error occurred:", error);
      return null;
    });
  }
  return null;
}

export async function getBusinessNumber(authentication) {

  const buildUrl = (accountSid = '', pageSize = 8, pageNumber = 0) =>
    `https://api.twilio.com/2010-04-01/Accounts/${accountSid}/IncomingPhoneNumbers.json?Beta=false&PageSize=${pageSize}&Page=${pageNumber}`

  const getTwilioPhoneNumbers = async (authentication, pageSize = 50, pageNumber = 0) => {
    return await axios.get(
      buildUrl(authentication.accountSid, pageSize, pageNumber),
      { auth: toTwilioCredentials(authentication) }
    )
  }

  let numbers = await getTwilioPhoneNumbers(authentication, 1).data?.incoming_phone_numbers;
  if (numbers != null && numbers.length > 0) {
    return numbers[0];
  }
  return null;
}

export async function sendSMSMessage(to, from, body, authentication, imageURL) {
  // const credentials = toTwilioCredentials(authentication);
  // const data = new URLSearchParams()
  // data.append('To', to)
  // data.append('From', from)
  // data.append('Body', body)
  // // imageURL = "https://www.webfx.com/wp-content/uploads/2023/07/what-is-openai.png"
  // console.log(imageURL)
  // if (imageURL != null) {
  //   data.append('MediaUrl', imageURL)
  // }
  // const url = `https://api.twilio.com/2010-04-01/Accounts/${authentication.accountSid}/Messages.json`
  // let messageResponse = await axios.post(url, data, {
  //   auth: credentials,
  //   headers: {
  //     'Content-Type': 'application/x-www-form-urlencoded'
  //   },
  // });

  // return messageResponse.data;
  console.log(to, body, "TESTING (after)");
  return await fetch(`${API_ENDPOINT}/v1/retention/${to}/message/send`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
    body: JSON.stringify({
      msg_body: body,
      msg_image: imageURL,
    })
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred.");
      return {
        status: false,
        message: "An error occurred.",
        data: null
      }
    }
    const data = await response.json();
    return {
      status: true,
      message: "",
      data: data
    }
  }).catch(error => {
    console.log("An error occurred:", error);
    return {
      status: false,
      message: error,
      data: null
    }
  });
}

export async function sendBlastSMSMessage(cgId, blastMessage, imageURL) {
  return await fetch(`${API_ENDPOINT}/v1/retention/blast_message`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
    body: JSON.stringify({
      msg_body: blastMessage,
      msg_image: imageURL,
      cg_id: cgId
    })
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred.");
      return null;
    }
    const data = await response.json();
    return data;
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });
}

export async function importCustomerCSVList(cgId, fileObj, smsColId = "", emailColId = "") {
  const formData = new FormData();
  formData.append('file', fileObj);
  // build import url
  let importURL = `${API_ENDPOINT}/v1/retention/csv/customers/import?cg_id=${cgId}`;
  if (smsColId != null && smsColId != "") {
    importURL = `${importURL}&sms_col_id=${smsColId}`;
  }
  if (emailColId != null && emailColId != "") {
    importURL = `${importURL}&email_col_id=${emailColId}`;
  }

  return await fetch(importURL, {
    method: 'POST',
    headers: {
      'x-access-token': localStorage.getItem('accessToken')
    },
    body: formData,
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred.");
      return null;
    }
    const data = await response.json();
    return data;
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });
}

export async function listRetentionCustomerGroups() {
  let listURL = `${API_ENDPOINT}/v1/retention/customer_groups/list`;
  return await fetch(listURL, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred.");
      return null;
    }
    const data = await response.json();
    return data;
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });
}

export async function listRetentionCustomers(cgId, limit = 10, offset = 0) {
  let listURL = `${API_ENDPOINT}/v1/retention/customers/list?cg_id=${cgId}&offset=${offset}`;
  console.log(limit, "TESTING");
  if (limit != null) {
    listURL = `${listURL}&limit=${limit}`
  }
  return await fetch(listURL, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred.");
      return null;
    }
    const data = await response.json();
    return data;
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });
}

export async function listRetentionCustomerColumns(cgId) {
  let listURL = `${API_ENDPOINT}/v1/retention/customer/columns/list?cg_id=${cgId}`;
  return await fetch(listURL, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred.");
      return null;
    }
    const data = await response.json();
    return data;
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });
}

export async function getCreativeEditorGraphicTemplates(limit = 10, offset = 0) {
  let listURL = `${API_ENDPOINT}/v1/creative_editor/graphic_templates/list?offset=${offset}`;
  if (limit != null) {
    listURL = `${listURL}&limit=${limit}`
  }
  return await fetch(listURL, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred.");
      return null;
    }
    const data = await response.json();
    return data;
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });
}

export async function createRetentionCampaign(flow, schedule) {
  if (flow != null) {
    return await fetch(`${API_ENDPOINT}/v1/retention/campaign/create`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('accessToken')
      },
      body: JSON.stringify({
        flow: flow,
        schedule: schedule
      })
    }).then(async (response) => {
      if (!response.ok) {
        console.log("An error occurred.");
        return null;
      }
      const data = await response.json();
      return data;
    }).catch(error => {
      console.log("An error occurred:", error);
      return null;
    });
  }
  return null;

}

export async function listCreativeEditorProjects() {
  let listURL = `${API_ENDPOINT}/v1/creative_editor/projects/list`;
  return await fetch(listURL, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred.");
      return null;
    }
    const data = await response.json();
    return data;
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });
}

export async function processCreativeEditorFonts(limit, offset, query) {
  let loadableFonts = CUSTOM_FONTS.slice().sort((a, b) => a.font_family.localeCompare(b.font_family));
  if (query != null && query != "") {
    loadableFonts = loadableFonts.filter(font => font.font_family.toLowerCase().includes(query.toLowerCase()));
  }
  let total_fonts = loadableFonts.length;
  let listURL = `${API_ENDPOINT}/v1/creative_editor/assets/fonts/process`;
  return await fetch(listURL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
    body: JSON.stringify({
      font_assets: loadableFonts.splice(offset, offset + limit)
    })
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred.");
      return null;
    }
    const data = await response.json();
    data.total_fonts = total_fonts;
    return data;
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });
}

export async function listCreativeEditorFontAssets(limit, offset, query) {
  let listURL = "";
  if (query == null || query == "") {
    listURL = `${API_ENDPOINT}/v1/creative_editor/assets/fonts/list?limit=${limit}&offset=${offset}`;
  } else {
    listURL = `${API_ENDPOINT}/v1/creative_editor/assets/fonts/search?limit=${limit}&offset=${offset}&query=${query}`;
  }
  return await fetch(listURL, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred.");
      return null;
    }
    const data = await response.json();
    return data;
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });
}

export async function listCreativeEditorAssets(assetType) {
  let listURL = `${API_ENDPOINT}/v1/creative_editor/assets/list?asset_type=${assetType}`;
  return await fetch(listURL, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred.");
      return null;
    }
    const data = await response.json();
    return data;
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });
}

export async function getCreativeEditorProject(projectId) {
  let getURL = `${API_ENDPOINT}/v1/creative_editor/projects/${projectId}`;
  return await fetch(getURL, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred.");
      return null;
    }
    const data = await response.json();
    return data;
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });
}

export async function createCreativeEditorFontAsset(assetName, assetFamily, assetPostscript, assetCategory, assetURL, assetDataURL, assetFilename) {
  let createURL = `${API_ENDPOINT}/v1/creative_editor/assets/fonts/create`;
  return await fetch(createURL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
    body: JSON.stringify({
      asset_name: assetName,
      asset_family: assetFamily,
      asset_postscript: assetPostscript,
      asset_category: assetCategory,
      asset_url: assetURL,
      asset_data_url: assetDataURL,
      asset_filename: assetFilename
    })
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred.");
      return null;
    }
    const data = await response.json();
    return data;
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });
}

export async function createCreativeEditorAsset(assetData, assetPreviewDataUrl, assetKeywords, assetType) {
  let createURL = `${API_ENDPOINT}/v1/creative_editor/assets/create`;
  return await fetch(createURL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
    body: JSON.stringify({
      asset_data: assetData,
      preview_data_url: assetPreviewDataUrl,
      asset_type: assetType,
      asset_keywords: assetKeywords
    })
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred.");
      return null;
    }
    const data = await response.json();
    return data;
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });
}

export async function createCreativeEditorProject(projectName) {
  let createURL = `${API_ENDPOINT}/v1/creative_editor/projects/create`;
  return await fetch(createURL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
    body: JSON.stringify({
      project_name: projectName
    })
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred.");
      return null;
    }
    const data = await response.json();
    return data;
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });
}

export async function updateCreativeEditorProject(projectId, projectName, editorState, previewDataUrl) {
  let updateURL = `${API_ENDPOINT}/v1/creative_editor/projects/${projectId}`;
  return await fetch(updateURL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
    body: JSON.stringify({
      project_name: projectName,
      editor_state: editorState,
      preview_data_url: previewDataUrl
    })
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred.");
      return null;
    }
    const data = await response.json();
    return data;
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });
}

export async function generateTextCreative(prompt, type) {
  if (prompt != null) {
    return await fetch(`${API_ENDPOINT}/v1/creative_editor/generate_text`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('accessToken')
      },
      body: JSON.stringify({
        prompt: prompt,
        type: type
      })
    }).then(async (response) => {
      if (!response.ok) {
        console.log("An error occurred.");
        return null;
      }
      const data = await response.json();
      return data;
    }).catch(error => {
      console.log("An error occurred:", error);
      return null;
    });
  }
  return null;

}
export async function generateImageCreative(prompt, type) {
  if (prompt != null) {
    return await fetch(`${API_ENDPOINT}/v1/creative_editor/generate_image`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('accessToken')
      },
      body: JSON.stringify({
        prompt: prompt,
        type: type
      })
    }).then(async (response) => {
      if (!response.ok) {
        console.log("An error occurred.");
        return null;
      }
      const data = await response.json();
      return data;
    }).catch(error => {
      console.log("An error occurred:", error);
      return null;
    });
  }
  return null;

}

export async function generateUpscaledImageCreative(prompt, type) {
  if (prompt != null) {
    return await fetch(`${API_ENDPOINT}/v1/creative_editor/generate_image`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('accessToken')
      },
      body: JSON.stringify({
        prompt: prompt,
        type: type
      })
    }).then(async (response) => {
      if (!response.ok) {
        console.log("An error occurred.");
        return null;
      }
      const data = await response.json();
      return data;
    }).catch(error => {
      console.log("An error occurred:", error);
      return null;
    });
  }
  return null;

}

export async function createUpcomingContent(contentType, platform, imagesContent, textContent, metadata) {
  if (platform != null && imagesContent != null && textContent != null && (imagesContent.length > 0 || textContent != "")) {
    return await fetch(`${API_ENDPOINT}/v1/upcoming_content/create`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('accessToken')
      },
      body: JSON.stringify({
        platform,
        content_type: contentType,
        images_content: imagesContent,
        text_content: textContent,
        metadata
      })
    }).then(async (response) => {
      if (!response.ok) {
        console.log("An error occurred.");
        return null;
      }
      const data = await response.json();
      return data;
    }).catch(error => {
      console.log("An error occurred:", error);
      return null;
    });
  }
  return null;
}

export async function respondToMetaPagePostComment(commentId, textContent) {
  if (textContent != null && textContent != "" && commentId && commentId != "") {
    return await fetch(`${API_ENDPOINT}/v1/meta/posts/comments/${commentId}/respond`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('accessToken')
      },
      body: JSON.stringify({
        text_content: textContent
      })
    }).then(async (response) => {
      if (!response.ok) {
        console.log("An error occurred.");
        return null;
      }
      const data = await response.json();
      return data;
    }).catch(error => {
      console.log("An error occurred:", error);
      return null;
    });
  }
  return null;
}

export async function respondToInstaPagePostComment(commentId, textContent) {
  if (textContent != null && textContent != "" && commentId && commentId != "") {
    return await fetch(`${API_ENDPOINT}/v1/insta/posts/comments/${commentId}/respond`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('accessToken')
      },
      body: JSON.stringify({
        text_content: textContent
      })
    }).then(async (response) => {
      if (!response.ok) {
        console.log("An error occurred.");
        return null;
      }
      const data = await response.json();
      return data;
    }).catch(error => {
      console.log("An error occurred:", error);
      return null;
    });
  }
  return null;
}

export async function sendMessengerMessage(recipientId, pageId, textContent) {
  if (textContent != null && textContent != "" && recipentId && recipentId != "" && pageId && pageId != "") {
    return await fetch(`${API_ENDPOINT}/v1/meta/${pageId}/messenger/${recipientId}/reply`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('accessToken')
      },
      body: JSON.stringify({
        text_content: textContent
      })
    }).then(async (response) => {
      if (!response.ok) {
        console.log("An error occurred.");
        return {
          status: false,
          message: "An error occurred.",
          data: null
        }
      }
      const data = await response.json();
      return {
        status: true,
        message: "",
        data: data
      }
    }).catch(error => {
      console.log("An error occurred:", error);
      return {
        status: false,
        message: "An error occurred.",
        data: null
      }
    });
  }
  return {
    status: false,
    message: "An error occurred.",
    data: null
  }
}

export async function getIntegrationsStatus() {
  let statusURL = `${API_ENDPOINT}/v1/integrations/statuses`;
  return await fetch(statusURL, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred.");
      return null;
    }
    const data = await response.json();
    return data;
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });
}

export async function handleMetaOAuth() {
  let oauthURL = `${API_ENDPOINT}/v1/meta/oauth`;
  return await fetch(oauthURL, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred.");
      return null;
    }
    const data = await response.json();
    if (data.redirect_url) {
      window.location.href = data.redirect_url;
    }
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });

}

export async function handleGoogleOAuth() {
  let oauthURL = `${API_ENDPOINT}/v1/google/oauth`;
  return await fetch(oauthURL, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred.");
      return null;
    }
    const data = await response.json();
    if (data.redirect_url) {
      window.location.href = data.redirect_url;
    }
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });

}

export async function listMediaLibrary(limit = 10, offset = 0) {
  let listURL = `${API_ENDPOINT}/v1/media_library/list?limit=${limit}&offset=${offset}`;
  return await fetch(listURL, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred.");
      return null;
    }
    const data = await response.json();
    return data;
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });
}

export async function deleteMediaLibraryItem(itemId) {
  let deleteURL = `${API_ENDPOINT}/v1/media_library/delete`;
  return await fetch(deleteURL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
    body: JSON.stringify({ item_id: itemId })
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred.");
      return null;
    }
    const data = await response.json();
    return data;
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });
}

export async function uploadMediaLibraryItem(file) {
  const formData = new FormData();
  formData.append('file', file);

  let uploadURL = `${API_ENDPOINT}/v1/media_library/upload`;
  try {
    const response = await fetch(uploadURL, {
      method: 'POST',
      headers: {
        'x-access-token': localStorage.getItem('accessToken')
      },
      body: formData
    });

    if (!response.ok) {
      console.log("An error occurred during upload.");
      return { success: false };
    }

    const data = await response.json();
    return { success: true, data };
  } catch (error) {
    console.log("An error occurred:", error);
    return { success: false };
  }
}

export async function searchMediaLibrary(queryText) {
  let searchURL = `${API_ENDPOINT}/v1/media_library/search`;
  return await fetch(searchURL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('accessToken')
    },
    body: JSON.stringify({ query_text: queryText })
  }).then(async (response) => {
    if (!response.ok) {
      console.log("An error occurred during search.");
      return null;
    }
    const data = await response.json();
    return data;
  }).catch(error => {
    console.log("An error occurred:", error);
    return null;
  });
}

export async function createStripePaymentIntent(amount, plan) {
  try {
    const response = await fetch(`${API_ENDPOINT}/v1/stripe/create_subscription_intent`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('accessToken')
      },
      body: JSON.stringify({ amount, selected_plan: plan }),
    });

    console.log(response);

    if (!response.ok) {
      throw new Error('Failed to create payment intent');
    }

    const data = await response.json();
    return {
      clientSecret: data.clientSecret,
      subscriptionId: data.subscriptionId
    };
  } catch (error) {
    console.error('Error creating payment intent:', error);
    throw error;
  }
}
